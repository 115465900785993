input:focus {
    /* border: 1px solid #00A6D9; */
}

/* input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    border: 2px solid #ccc !important;
    border-radius: 100%;
    height: 15px; 
    width: 15px;
}

input[type='radio']:checked {
    background-color: #00A6D9;
    border-color: #00A6D9
} */

.anInput{
    border-color: #ccc;
    border-radius: 3px;
}

.anInput:focus{
    outline: none;
    border: 1px solid #00A6D9;
}

.anInput:focus-within{
    border: 1px solid #00A6D9 !important;
}
.anInput{
    border: 1px solid #ccc !important;
}


.hideBorderOnFocusInput:focus{
    outline: none;
}

.react-date-picker__wrapper:focus-within{
    border: 1px solid #00A6D9 !important;
}

