.App {
  text-align: center;
  font-family: 'Lato', sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.editButton:hover{
 box-shadow: 0px 1px 3px rgba(0,0,0,0.2), 0px 4px 10px rgba(0,0,0,0.2);
}

.actionButton:hover{
  box-shadow: 0px 1px 3px rgba(0,0,0,0.2), 0px 4px 10px rgba(0,0,0,0.2);
  background-color: #495966 !important;
  cursor: pointer
}


.react-date-picker__wrapper {
  padding: 15px;
 border: 1px solid #ccc !important;
 border-radius: 2px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

input[type=radio] {
  cursor: pointer
}